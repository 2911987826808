<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">充电桩管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/charging/chargingDevice">用户管理</a>
      </el-breadcrumb-item>
    </el-breadcrumb> 
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
      <el-form-item label="电话号码" prop="phone">
        <el-input type="text" size="mini" v-model="queryModel.phone"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
        >查询</el-button>&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
        >重置</el-button>&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        :disabled="multipleSelection.length==0"
        @click="handleBatchDelete"
      >删除选中项</el-button>
      <el-button
          type="primary"
          size="small"
          plain
          icon="el-icon-download"
          @click="exportXls"
      >导出数据</el-button>
    </el-row>
    <el-table
      :data="tableData"
      style="min-height:400px;"
      v-loading="loading"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="headImg" label="头像">
        <template slot-scope="{ row }">
          <a :href="row.headImg" target="_blank">
            <el-avatar
              :size="48"
              shape="circle"
              :src="
                row.headImg +
                '?x-oss-process=image/resize,m_fill,w_64,h_64'
              "
              :key="row.id"
            ></el-avatar>
          </a>
        </template>
      </el-table-column>
      <el-table-column
        prop="phone"
        label="电话号码"
      ></el-table-column>
      <el-table-column
        prop="accountBalance"
        label="账户余额（元）"
      ></el-table-column>
      <el-table-column
        prop="openId"
        label="openId"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="注册时间"
      ></el-table-column>

      <el-table-column label="操作" fixed="right">
        <template slot-scope="{row}">
          <el-link type="primary" :underline="false" @click="handleEdit(row)">编辑</el-link>-
          <el-link type="primary" :underline="false" @click="handleBalanceList(row)">余额情况</el-link>-
          <el-link type="danger" :underline="false" @click="handleDelete(row)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <chargingRegUser-detail
      v-if="showModal"
      :businessKey="businessKey"
      :title="modalTitle"
      @close="onDetailModalClose"
    ></chargingRegUser-detail>
    <chargingRegUser-balanceList
      v-if="showModal2"
      :businessKey="businessKey"
      :title="modalTitle2"
      @close="onDetailModalClose2"
    ></chargingRegUser-balanceList>
  </div>
</template>
<script>
import chargingRegUserDetail from "./chargingRegUser-detail";
import chargingRegUserBalanceList from "./chargingRegUser-balanceList";
import chargingRegUserApi from "@/api/base/chargingRegUser";
import "nprogress/nprogress.css"; // progress bar style

export default {
  name: "chargingChargingRegUserList",
  data() {
    var self = this;

    return {
      queryModel: {
        phone:"",
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      showModal2: false,
      modalTitle2: "",
      selectedRow: {},
    };
  },
  methods: {
    handleBindClass(row) {
      this.selectedRow = row;
      this.showBindModal = true;
    },
    handleCloseLive() {
      if(this.player!=null){
        this.player.pause();
      }
    },
    changePage(pageIndex,exportFlag) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);

      if(exportFlag!=null){
          formData.append("exportFlag", exportFlag);
          formData.append("pageSize", 10000);
      }
      else{
          formData.append("pageSize", self.pageSize);
      }

      formData.append("phone", self.queryModel.phone);

      chargingRegUserApi
        .pageList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data;
          if(jsonData.result) {
              if(exportFlag){

                  //导出
                  self.$message({
                      showClose: true,
                      type: "success",
                      message: `报表已生成，<a href="${jsonData.data}">请点击链接下载</a>`,
                      dangerouslyUseHTMLString: true,
                      duration: 30000
                  });
              }
              else{
                  //分页查看
                  var page = jsonData.data;
                  self.tableData = page.data;
                  self.totalPages = page.totalPages;
                  self.totalElements = page.recordsTotal;
              }
          }
          else{
              self.$message.warning(jsonData.message);
          }
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;

      this.$nextTick(() => {
        this.changePage(this.pageIndex);
      });
    },
    sortChange(data) {
      this.field = data.column.sortBy;
      this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.businessKey = record.id;
      this.showModal = true;
    },
    handleBalanceList(record){
      this.modalTitle2 = "余额情况";
      this.businessKey = record.id;
      this.showModal2 = true;
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          chargingRegUserApi.remove(record.id).then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        chargingRegUserApi.batchRemove(idList).then(function (response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
      });
    },
    exportXls() {
        this.changePage(1,true);
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    onDetailModalClose2(refreshed) {
      //保存成功后回调
      this.showModal2 = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
  },
  created() {

  },
  mounted: function () {
    this.changePage(1);
  },
  components: {
    "chargingRegUser-detail": chargingRegUserDetail,
    "chargingRegUser-balanceList":chargingRegUserBalanceList
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}

.capture-image{
  width:160px;
  height: 90px;
  object-fit: container;
}
</style>